import '../css/login.css';
import { useNavigate } from "react-router"
import {StaticHeader} from '../StaticComponents'
import { setToken, setUserLetter } from "./Auth"
import LoginPage, { Password, Input, Email, Title, Submit, Reset, Logo, Footer } from '@react-login-page/page4';
import LoginLogo from 'react-login-page/logo';
import { useState } from "react"
import axios from "axios"
import GeneralVariables from "../variables/generalVariables";

function isValidEmail(email) {
  // Define a regular expression pattern for email validation.
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
}

function LoginScreen() {
    const header = StaticHeader(false, "");
    const navigate = useNavigate();
    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [validationError,setValidationError] = useState('')
    const [organization,setOrganization] = useState('')

     const login = (method) => {
         if(name === '' && email === ''){
            setValidationError(<div style={{color: '#eb3c02'}} className="error">Email & Name moet ingevuld zijn</div>)
         } else if (!isValidEmail(email)) {
            setValidationError(<div style={{color: '#eb3c02'}} className="error">Email ongeldig</div>)
         } else {
            // make api call to our backend. we'll leave thisfor later
             axios.post(GeneralVariables.server_address + 'login',{
                 name: name,
                 email: email,
                 organization: organization,
                 phone: phone
             })
             .then(function(response){
               if(response.data.token){
                 setToken(response.data.token)
                 setUserLetter(Array.from(name)[0].toUpperCase())
                 navigate("/" + method + "/vraag");
               }
             })
             .catch(function(error){
               console.log(error,'error');
             });
         }
     }

    return (
        < div className="App">
            {header}
            <div className="body">
                <LoginPage>
                    <Input placeholder="Naam" name="name" index={1} onChange={(e)=>setName(e.target.value)} />
                    <Email placeholder="Email" name="email" index={2} onChange={(e)=>setEmail(e.target.value)} />
                    <Input placeholder="Organisatie" index={3} name="organization" onChange={(e)=>setOrganization(e.target.value)} />
                    <Input placeholder="Telefoonnummer" name="phone" index={4} onChange={(e)=>setPhone(e.target.value)} />
                    <Password visible={false} />
                    <Title visible={false} />
                    <Logo visible={false}>
                        <LoginLogo visible={false} height={31} />
                    </Logo>
                    <Reset index={5} onClick={() => login("WatsonX")}>Inloggen WatsonX</Reset>
                    <Submit index={6} onClick={() => login("Watson")}>Inloggen Watson</Submit>
                    <Footer>{validationError}</Footer>
                </LoginPage>
            </div>
        </div>
    );
}

export default LoginScreen;
