import '../css/info.css';
import {StaticHeader, StaticFooter} from '../StaticComponents'
import React, {useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfoOptions from "../variables/infoSentences";

import { fetchUserLetter } from './Auth'

function Info() {
    const { service } = useParams();
    const header = StaticHeader(true, fetchUserLetter());

    const ref = useRef();
    const refExample = useRef();
    const refHowToUse = useRef();
    const refDataOrigin = useRef();
    const refContact = useRef();

    const scrollBottom = (ref) =>
        ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
    });

    let navigate = useNavigate();
    const routeChange = () =>{
        let path = '/' + service + '/vraag';
        navigate(path);
    }

    const InfoHeaders = (headerName, ref) => {
        if (headerName === InfoOptions.backToChat) {
            return (
                <div className="headerButtons">
                    <button className="headerButton" onClick={routeChange}>
                        {headerName}
                    </button>
                </div>
            )
        } else {
            return (
                <div className="headerButtons">
                    <button className="headerButton" onClick={() => scrollBottom(ref)}>
                        {headerName}
                    </button>
                </div>
            )
        }
    }

    const infoTextLayout = (headerName) => {
        if (headerName === InfoOptions.usage) {
            return (
                <div>
                    Mikkie heeft als doel het zoeken naar waardevolle informatie binnen al uw documenten te vereenvoudigen. Door in natuurlijke taal een vraag te stellen over uw eigen documenten, en daarmee ook een antwoord terug krijgen vanuit uw eigen documenten content.
                    <br/><br/>
                    Mikkie bestaat uit verschillende onderdelen waar uit gekozen kunnen worden tijdens een zoekopdracht. Allereerst is het belangrijk dat er een vraag is die beantwoord kan worden binnen de eigen documenten content. Als tweede is er de optie om de document set waarin gezocht wordt te limiteren door middel van filters. Welke filters er allemaal mogelijk zijn is vrij onbeperkt, denk bijvoorbeeld aan:
                    <br/><br/>
                     <ul>
                        <li> Document extensie </li>
                        <li> Creatie datum </li>
                        <li> Auteur </li>
                     </ul>

                    Als laatst is er nog de optie om de vraag door een Large Language Model (LLM) te laten beantwoorden. Hiermee krijg je in natuurlijke taal een antwoord op de gestelde vraag aan de hand van gevonden relevante documenten. Er wordt geen internet bij gebruikt. Het bekijken van de relevante documenten is altijd een optie, ook als u geen LLM gegenereerd antwoord wilt. Dan kunt u zelf een antwoord vormen.
                </div>
            )
        }

        if (headerName === InfoOptions.examples) {
            return (
                <div>
                    Vragen die u bijvoorbeeld aan de Mikkie chatbot kunt stellen zijn als volgt:
                    <br/><br/>
                     <ul>
                        <li> Voorstel van wet houdende regels ter uitvoering van Verordening van het Europees Parlement betrof wat? </li>
                        <li> Waarom kon Vijlbrief geen oordeel geven over het debat van 2 juni jl.? </li>
                        <li> Besluit 2 mei 2023 omgevingsvergunning rijksmonument 17 mei 2023? </li>
                     </ul>
                </div>
            )
        }

        if (headerName === InfoOptions.dataOrigin) {
            return (
                <div>
                    De data welke op dit moment gebruikt wordt komt van de rijksoverheid en is publiekelijk beschikbaar.
                </div>
            )
        }

        if (headerName === InfoOptions.contact) {
            return (
                <div>
                    Voor meer informatie, een toelichting, uitgebreide demonstratie of een inschatting van de kosten bij een in-huis inrichting, via een private Cloud of via de Migrato Cloud, neem dan contact met ons op. Via email met <a href = "mailto:oscar.dubbeldam@migrato.nl"> oscar.dubbeldam@migrato.nl </a>, of telefonisch via 010 – 321.62.44.
                </div>
            )
        }
    }

    const InfoBody = (headerName, headerBody, ref) => {
        return (
            <div className="infoBody">
                <div ref={ref}/>
                <div className="infoHeader">
                    {headerName}
                </div>
                <div className="infoText">
                    {infoTextLayout(headerName)}
                </div>
            </div>
        )
    }

    return (
        < div className="App">
            {header}
            <div className="body">
                <div className="info">
                    <div className="infoOptionsHeader">
                        {InfoHeaders(InfoOptions.backToChat, ref)}
                        {InfoHeaders(InfoOptions.usage, refHowToUse)}
                        {InfoHeaders(InfoOptions.examples, refExample)}
                        {InfoHeaders(InfoOptions.dataOrigin, refDataOrigin)}
                        {InfoHeaders(InfoOptions.contact, refContact)}
                    </div>
                    <div className="infoWindow">
                        {InfoBody(InfoOptions.usage, InfoOptions.usageInfo, refHowToUse)}
                        {InfoBody(InfoOptions.examples, InfoOptions.examplesInfo, refExample)}
                        {InfoBody(InfoOptions.dataOrigin, InfoOptions.dataOriginInfo, refDataOrigin)}
                        {InfoBody(InfoOptions.contact, InfoOptions.contactInfo, refContact)}
                    </div>
                </div>
            </div>
            <StaticFooter/>
        </div>
    );
}

export default Info;
