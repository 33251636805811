import '../css/question.css';
import {StaticHeader, StaticFooter} from '../StaticComponents'
import kameleonLogo from "../images/kameleon.png";
import ChatOptions from "../variables/chatSentences";
import { useNavigate, useParams } from "react-router-dom";
import {
    TextField
} from "@mui/material";

import {useState} from "react";

import {Tooltip} from "react-tooltip";

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

import { fetchUserLetter } from './Auth'


function Question() {
    const { service } = useParams();
    const header = StaticHeader(true, fetchUserLetter());

    let navigate = useNavigate();
    const routeChangeInfo = () => {
        let path = '/' + service + '/info';
        navigate(path);
    }

    const routeChangeChat = () => {
    console.log(question)
        let path = '/' + service + '/chat';
        navigate(path, {state: {id: question}});
    }

    const [question, setQuestion] = useState("")

    return (
        <div className="App">
            {header}
            <div className="body">
                <div className="logoAboveQuestion">
                    <div className="logoBig">
                        <img src={kameleonLogo} alt="Logo" className="logoImageBig"/>
                    </div>
                    <div className="triangle"/>
                    <div className="circleAbsolute">
                        <div className="oval">
                            <p className="cameleonText">
                                {ChatOptions.topChameleonOne}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="questionCenterLayout">
                    <div className="questionBox">
                        <div className="questionBoxLeftIcon">
                            <ChatBubbleOutlineIcon />
                        </div>
                        <div className="questionBoxInputField">
                            <TextField id="userQuestion" label={ChatOptions.typeResponse} variant="standard" fullWidth
                                InputLabelProps={{
                                    style: {
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        width: '100%',
                                        color: 'white'
                                    }
                                }}
                                sx={{
                                    input: {
                                        color: 'white',
                                        borderBottom: '2px solid gray',
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottomColor: "orange"
                                    }
                                }}
                                onKeyDown={(ev) => {
                                    if (ev.key === 'Enter') {
                                        routeChangeChat()
                                    }
                                }}
                                onChange={(ev) => {
                                    setQuestion(ev.target.value)
                                }}
                            />
                        </div>
                        <div className="questionBoxRightIcon">
                            <ArrowUpwardIcon className="arrowUp" onClick={() => routeChangeChat()}/>
                        </div>
                    </div>
                    <div className="generalOptions">
                        <div className="generalButtonMic" data-tooltip-id="micChatTooltip">
                             <KeyboardVoiceIcon />
                        </div>
                        <Tooltip
                             id="micChatTooltip"
                             place="bottom"
                             content="Klik hier om via spraak te reageren. NOG NIET BESCHIKBAAR."
                         />
                         <div className="generalButtonFiller" />
                        <div className="generalButtonInfo" onClick={routeChangeInfo} data-tooltip-id="infoPageTooltip">
                            <p className="generalButtonText">?</p>
                        </div>
                        <Tooltip
                             id="infoPageTooltip"
                             place="bottom"
                             content="Klik hier als u naar de info pagina wilt."
                         />
                    </div>
                </div>
            </div>
            <StaticFooter/>
        </div>
    )
}

export default Question;