import Chat from './pages/chat'
import Login from './pages/login'
import Info from './pages/info'
import Question from './pages/question'
import { RequireToken} from './pages/Auth'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";


function App() {

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route path="/:service/vraag" element={ <RequireToken><Question /></RequireToken>} />
                <Route path="/:service/chat" element={ <RequireToken><Chat /></RequireToken> } />
                <Route path="/:service/info" element={ <RequireToken><Info /></RequireToken> } />
            </Routes>
        </Router>
    );
}

export default App;
