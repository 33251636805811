import { useLocation,Navigate } from "react-router-dom"



export const setToken = (token)=>{

    localStorage.setItem('micc-user-token', token)// make up your own token
}

export const fetchToken = (token)=>{

    return localStorage.getItem('micc-user-token')
}

export function RequireToken({children}){

    let auth = fetchToken()
    let location = useLocation()

    if(!auth){

        return <Navigate to='/' state ={{from : location}}/>;
    }

    return children;
}

export const setUserLetter = (letter) => {

    localStorage.setItem('micc-user-letter', letter)// make up your own token
}

export const fetchUserLetter = () => {

    return localStorage.getItem('micc-user-letter')
}