const InfoOptions = {
    backToChat: "Terug naar chat",
    examples: "Voorbeelden",
    usage: "Hoe te gebruiken",
    dataOrigin: "Waar komt de data vandaan",
    contact: "Contact",

    examplesInfo: "Een paar voorbeeld zoekopdrachten zijn als volgt: Wanneer werd de motie-Erkens aangenomen? Waarom kon Vijlbrief geen oordeel geven over het debat van 2 juni jl.? Besluit 2 mei 2023 omgevingsvergunning rijksmonument 17 mei 2023?",
    usageInfo: "De Migrato Chat kan veelzijdig gebruikt worden. Er is een optie tot het toepassen van filters, en ook tot het laten genereren van een antwoord op de gestelde vraag in plaats van alleen maar documenten terug krijgen. U begint een zoekopdracht door eerst te kiezen tussen de opties: wel of niet filteren. Hiermee kunt u de zoekopdracht beperken naar eigen wens. Hierna wordt een tweede keuze menu getoond. In dit keuze menu kunt u kiezen tussen alleen relevante documenten terug krijgen of een gegenereerd antwoord op uw vraag op basis van de relevante documenten. Na beide keuze menus kunt u uw vraag invoeren. Na het invoeren gaat de chat gelijk aan de slag met uw zoekopdracht.",
    dataOriginInfo: "Alle data die gebruikt wordt door Migrato Chat is zelf opgegeven vanuit eigen brondocumenten. Er wordt niet op het internet gezocht naar anwoorden.",
    contactInfo: "Bij meer informatie kunt u contact opnemen met ons. www.migrato.nl",
}

export default InfoOptions