import './css/App.css';
import React from "react"
import migratoLogo from "./images/logo.png";

import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.css';

import AppsIcon from '@mui/icons-material/Apps';
import LightModeIcon from '@mui/icons-material/LightMode';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';

import {Tooltip} from "react-tooltip";

import { useNavigate } from "react-router-dom";

export function StaticHeader(loggedIn, userLetter) {

    let navigate = useNavigate();
    const logout = () => {
        let path = '/';
        navigate(path);
    }

    return (
        <div className="headerBar">
            <div className="logo">
                <img src={migratoLogo} alt="Logo" className="logoImage"/>
            </div>
            <div className="loginIconBox">
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <AppsIcon className="settingsIcon"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item data-tooltip-id="darkLightModeTooltip"> <LightModeIcon /> Dark/Light mode</Dropdown.Item>
                        <Dropdown.Item data-tooltip-id="languageTooltip"> <LanguageIcon /> Taal</Dropdown.Item>
                        <Dropdown.Item data-tooltip-id="settingsTooltip"> <SettingsIcon /> Instellingen</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Tooltip
                    id="darkLightModeTooltip"
                    place="left"
                    content="Klik hier als u wilt wisselen tussen dark en light mode. NOG NIET BESCHIKBAAR."
                />

                <Tooltip
                    id="languageTooltip"
                    place="left"
                    content="Klik hier als u van taal wilt veranderen. NOG NIET BESCHIKBAAR."
                />

                <Tooltip
                    id="settingsTooltip"
                    place="left"
                    content="Klik hier als u de instellingen wilt aanpassen. NOG NIET BESCHIKBAAR."
                />

                <div className="loginFillerBox" />

                {loggedIn &&
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <div className="circle">
                                    <p className="textLoginUser">{userLetter}</p>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item data-tooltip-id="historyTooltip"> <HistoryIcon /> Geschiedenis</Dropdown.Item>
                                <Dropdown.Item className="dropDownItemAvailable" data-tooltip-id="logoutTooltip" onClick={() => logout()}> <LogoutIcon /> Uitloggen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Tooltip
                            id="logoutTooltip"
                            place="left"
                            content="Klik hier als u wilt uitloggen."
                        />
                        <Tooltip
                            id="historyTooltip"
                            place="left"
                            content="Klik hier als u uw eerder gestelde vragen wilt terug bekijken. NOG NIET BESCHIKBAAR."
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export function StaticFooter() {
    return (
        <div className="footer">
            <p className="text">
                DISCLAIMER: MICC Knowledge Base baseert zijn antwoorden op de toegevoegde documenten. Houd hiermee
                rekening in het gebruiken van het antwoord.
            </p>
        </div>
    )
}