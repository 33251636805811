import '../../css/chunkViewer.css'
import React, { useState } from "react";
import {Tooltip} from "react-tooltip";

import LinkIcon from '@mui/icons-material/Link';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

function ChunkViewer(documents) {
    const [expandedChunk, setExpandedChunk] = useState({})

    function editExpandChunk(location) {
    console.log(location)
        expandedChunk[location] = !expandedChunk[location]
        setExpandedChunk({...expandedChunk})
    }

    const DocumentChunks = (documentChunks) => {
        let chunkss = documentChunks['parameter']
        console.log(chunkss)
        let chunks = chunkss['chunk']
        console.log(chunks)
        const chunk = []
        for (let i = 1; i <= Object.keys(chunks).length; i++) {
            if (expandedChunk[chunkss['uri_source'] + i] !== false && expandedChunk[chunkss['uri_source'] + i] !== true) {
                expandedChunk[chunkss['uri_source'] + i] = false
            }
            let relevancy_score = Math.round((parseFloat(chunks[i]['relevancy_score']) * 100 + Number.EPSILON) * 100) / 100
            chunk.push(
                <div>
                    <div className="passageNumberSection">
                        <div className="passageNumberTitle">
                            <p style={{"color": "white", "fontSize": "0.8vw", "borderTopStyle": "dotted", "paddingTop":"1vh"}}>
                                Passage #{i} ({relevancy_score}%)
                            </p>
                        </div>
                        <div className="passageNumberTriangle">
                            {expandedChunk[chunkss['uri_source'] + i] ? (
                                    <ChangeHistoryIcon style={{color: 'white'}} className="passageTriangle" onClick={() => editExpandChunk(chunkss['uri_source'] + i)}/>
                                ) : (
                                    <ChangeHistoryIcon style={{transform: 'rotate(180deg)', color: 'white'}} className="passageTriangle" onClick={() => editExpandChunk(chunkss['uri_source'] + i)}/>
                                )
                            }
                        </div>
                    </div>
                    {expandedChunk[chunkss['uri_source'] + i] ? (
                        <div className="chunkText">
                            <p style={{"color": "white", "fontSize": "0.7vw"}}>
                                {chunks[i]['text']}
                            </p>
                        </div>
                        ) : null
                    }
                </div>
            )
        }
        return chunk
    }

    return (
        <div className="chunks">
            {
                Object.keys(documents['parameter']['documents']).map((key, index) => (
                    <div className="chunkObject">
                    <div className="chunkObjectBackground">
                        <div className="title">
                            <p className="chunkViewerText">
                                {documents['parameter']['documents'][key]['title']}
                                <LinkIcon className="urisourceLinkIcon"
                                    data-tooltip-id="copyUrisourceTooltip"
                                    onClick={() => {navigator.clipboard.writeText(documents['parameter']['documents'][key]['uri_source'])}}
                                />
                                <Tooltip
                                    id="copyUrisourceTooltip"
                                    place="bottom"
                                    content="Klik hier om de urisource te kopiëren naar het klembord."
                                />
                            </p>
                        </div>
                        <div className="chunkTexts">
                            <DocumentChunks parameter={(documents['parameter']['documents'][key])} />
                        </div>
                    </div>
                    </div>
                ))
            }

        </div>
    )
}

export default ChunkViewer;