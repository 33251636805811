const ChatOptions = {
    askQuestion: "Op welke vraag wilt u een antwoord?",
    answerUseGuideLLM: "Na het opgeven van uw vraag zal het antwoord automatisch gegenereerd worden.",
    answerUseGuideDocuments: "Na het opgeven van uw vraag zal er aan de rechterkant een menu openen met daarin de relevante documenten.",
    filterUseGuide: "De filter opties zijn getoond aan de linkerkant van het scherm. Vul deze eerst naar wens in voor u uw vraag opgeeft.",
    generateAnswerWithLLM: "Antwoord genereren",
    generateAnswerWithOnlyDocuments: "Alleen documenten tonen",
    typeResponse: "Stel een vraag...",
    typeResponseSecond: "Bericht Mikkie...",
    topChameleonOne: "Vraag het Mikkie!",
    filterLocation: "Aan de linkerzijde heeft zich een extra scherm geopend waarop de verschillende filter opties staan. Pas deze naar wens aan voor u mijn volgende vraag beantwoord."
}

export default ChatOptions