import '../css/chat.css';
import {StaticHeader, StaticFooter} from '../StaticComponents'
import React, {useRef, useState} from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { fetchToken} from './Auth'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup, Slider,
    TextField,
    Typography
} from "@mui/material";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import kameleonLogo from "../images/kameleon.png";
import ChatOptions from "../variables/chatSentences";
import GeneralVariables from "../variables/generalVariables";
import ChunkViewer from "./subpages/chunkViewer";
import {Tooltip} from "react-tooltip";
import Select from "react-select";
import {fetchEventSource} from "@microsoft/fetch-event-source";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import DescriptionIcon from '@mui/icons-material/Description';
import ChatIcon from '@mui/icons-material/Chat';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MessageIcon from '@mui/icons-material/Message';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

import { fetchUserLetter } from './Auth'

import Spinner from 'react-bootstrap/Spinner';

function Chat({route}) {
    const { service } = useParams();
    const location = useLocation()
    const header = StaticHeader(true, fetchUserLetter());

    let navigate = useNavigate();
    const routeChange = () =>{
        let path = '/' + service + '/info';
        navigate(path);
    }

    const routeChangeVraag = () =>{
        let path = '/' + service + '/vraag';
        navigate(path);
    }

    const [inputTextVal, setInputTextVal] = useState("")

    const [beginChatFirstAnswer] = useState("")
    const [beginChatSecondAnswer] = useState("")
    const [question] = useState(location.state.id)
    const [noResults, setNoResults] = useState(false)

    const [filterData, setFilterData] = useState([])
    const [sliderVersion, setSliderVersion] = useState({})
    const [sliderRange, setSliderRange] = useState({})
    const [sliderExact, setSliderExact] = useState({})
    const [dropdownSelection] = useState({})

    const [dataLLMResponse, setLLMResponse] = useState("");
    const [dataLLMResponseAdvanced, setLLMResponseAdvanced] = useState("")
    const [llmResponseRequested, setLlmResponseRequested] = useState(false)

    const refChat = useRef()
    const scrollBottom = () => {
        setTimeout(function () {
            refChat.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
            })
        }, 100);
    };

    const [documents, setDocuments] = useState([])
    let orderedAssetIds = []

    const [useFilters, setUseFilters] = useState(null)
    const [useLLM, setUseLLM] = useState(false)
    const [usePassages, setUsePassages] = useState(false)

    const [passageRequestSend, setPassageRequestSend] = useState(false)
    const [passageViewRequest, setPassageViewRequest] = useState(false)

    const [timeArray] = useState({})

    const date = new Date()
    const showTime = date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()


    function ShowFilterOptions() {
        return (
            <div className="filterSpeechBubble">
                <div className="filterSpeechBubbleText">
                    Wilt u filters toepassen op de gestelde vraag voor ik deze beantwoord?
                </div>
                <div className="filterSpeechBubbleIcons" disabled={useFilters !== null}>
                    <FilterAltIcon style={{color: 'white'}} className="filterSpeechBubbleIcon"
                        data-tooltip-id="filterTooltip"
                        onClick={() => setUseFilters(true)}
                    />
                    <div className="filterSpeechBubbleIconsFiller" />
                    <FilterAltOffIcon style={{color: 'white'}} className="filterSpeechBubbleIcon"
                        data-tooltip-id="geenFilterTooltip"
                        onClick={() => setUseFilters(false)}
                    />

                    <Tooltip
                        id="filterTooltip"
                        place="bottom"
                        content="Klik hier als u filters wilt gebruiken"
                    />
                    <Tooltip
                        id="geenFilterTooltip"
                        place="bottom"
                        content="Klik hier als u GEEN filters wilt gebruiken"
                    />
                </div>
            </div>
        )
    }

    function ShowAnsweringOptions() {
        return (
            <div className="answeringSpeechBubble">
                <div className="answeringSpeechBubbleText">
                    Wilt u dat ik de gestelde vraag beantwoord of dat u zelf naar de relevante passages kan kijken?
                </div>
                <div className="answeringSpeechBubbleIcons" disabled={useLLM === true || usePassages === true}>
                    <ChatIcon style={{color: 'white'}} className="answeringSpeechBubbleIcon"
                        data-tooltip-id="answeringLlmTooltip"
                        onClick={() => setUseLLM(true)}
                    />
                    <div className="filterSpeechBubbleIconsFiller" />
                    <DescriptionIcon style={{color: 'white'}} className="answeringSpeechBubbleIcon"
                        data-tooltip-id="answeringDocumentsTooltip"
                        onClick={() => setUsePassages(true)}
                    />

                    <Tooltip
                        id="answeringLlmTooltip"
                        place="bottom"
                        content="Klik hier als u wilt dat ik de vraag beantwoord"
                    />
                    <Tooltip
                        id="answeringDocumentsTooltip"
                        place="bottom"
                        content="Klik hier als u de relevante passages alleen wilt zien"
                    />
                </div>
            </div>
        )
    }

    function ChatAskQuestion(response) {
        if (timeArray[response['parameter']] === undefined) {
            timeArray[response['parameter']] = showTime
        }

        return (
            <div>
                <div className="askQuestion">
                    <div className="askQuestionFiller" />
                    <div className="askQuestionBot">
                        <p className="botText">
                            {response['parameter'] === 'sixth' &&
                                ChatOptions.askQuestion
                            }
                            {response['parameter'] === 'fifth' && beginChatSecondAnswer === ChatOptions.generateAnswerWithLLM &&
                                ChatOptions.answerUseGuideLLM
                            }
                            {response['parameter'] === 'fifth' && beginChatSecondAnswer === ChatOptions.generateAnswerWithOnlyDocuments &&
                                ChatOptions.answerUseGuideDocuments
                            }
                            {response['parameter'] === 'fourth' &&
                                ChatOptions.filterUseGuide
                            }
                            {response['parameter'] === 'askForFiltering' &&
                                <ShowFilterOptions />
                            }
                            {response['parameter'] === 'showFilterLocation' &&
                                ChatOptions.filterLocation
                            }
                            {response['parameter'] === 'answeringMethod' &&
                                <ShowAnsweringOptions />
                            }
                        </p>
                        <div className="botTimeInBubble">
                            {timeArray[response['parameter']]}
                        </div>
                    </div>
                </div>
                <img src={kameleonLogo} alt="Logo" className="logoImageSmall"/>
            </div>
        )
    }

    function ChatShowBotAnswer(response, llmResponse, loading) {
        if (timeArray[response] === undefined) {
            timeArray[response] = showTime
        }

        if (loading) {
            return (
                <div>
                    <div className="askQuestion">
                        <div className="askQuestionFiller" />
                        <div className="askQuestionBot">
                            <Spinner animation="border" variant="warning" />
                            <div className="botTimeInBubble">
                                {timeArray[response]}
                            </div>
                        </div>
                    </div>
                    <img src={kameleonLogo} alt="Logo" className="logoImageSmall"/>
                </div>
            )
        } else if (!llmResponse) {
            return (
                <div>
                    <div className="askQuestion">
                        <div className="askQuestionFiller" />
                        <div className="askQuestionBot">
                            <p className="botText">
                                {response}
                            </p>
                            <div className="botTimeInBubble">
                                {timeArray[response]}
                            </div>
                        </div>
                    </div>
                    <img src={kameleonLogo} alt="Logo" className="logoImageSmall"/>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="askQuestion">
                        <div className="askQuestionFiller" />
                        <div className="askQuestionBot">
                            <p className="botText">
                                {response}
                            </p>
                            <div className="botTimeInBubble">
                                {timeArray[response]}
                            </div>
                        </div>
                    </div>
                    <div className="llmAnswerOptionsDiv">
                        <img src={kameleonLogo} alt="Logo" className="logoImageSmall"/>
                        <div className="llmAnswerOptionsPadding" />
                        <div className="llmAnswerOptions">
                            <div className="llmAnswerOption">
                                <RestartAltIcon style={{color: 'white'}} className="llmAnswerOptionIcon" data-tooltip-id="redoTextLlmTooltip"/>
                            </div>
                            <div className="llmAnswerOptionPadding" />
                            <div className="llmAnswerOption">
                                <ChatIcon style={{color: 'white'}} className="llmAnswerOptionIcon" data-tooltip-id="lessTextLlmTooltip"/>
                            </div>
                            <div className="llmAnswerOptionPadding" />
                            <div className="llmAnswerOption">
                                <MessageIcon style={{color: 'white'}} className="llmAnswerOptionIcon" data-tooltip-id="moreTextLlmTooltip"/>
                            </div>
                            <div className="llmAnswerOptionPadding" />
                            <div className="llmAnswerOption">
                                <SpeakerNotesIcon style={{color: 'white'}} className="llmAnswerOptionIcon" data-tooltip-id="reasonTextLlmTooltip"/>
                            </div>
                            <div className="llmAnswerOptionPadding" />
                            <div className="llmAnswerOption">
                                <ContentCopyIcon style={{color: 'white'}} className="llmAnswerOptionIcon" data-tooltip-id="copyTextLlmTooltip"/>
                            </div>
                            <div className="llmAnswerOptionPadding" />
                            <div className="llmAnswerOptionAllowed">
                                <DescriptionIcon style={{color: 'white'}} className="llmAnswerOptionIcon" data-tooltip-id="sourcesTextLlmTooltip" onClick={() => setPassageViewRequest(true)}/>
                            </div>

                            <Tooltip
                                id="redoTextLlmTooltip"
                                place="bottom"
                                content="Klik hier als u een nieuw antwoord wilt laten genereren. NOG NIET BESCHIKBAAR."
                            />
                            <Tooltip
                                id="lessTextLlmTooltip"
                                place="bottom"
                                content="Klik hier als u een korter antwoord wilt. NOG NIET BESCHIKBAAR."
                            />
                            <Tooltip
                                id="moreTextLlmTooltip"
                                place="bottom"
                                content="Klik hier als u een langer antwoord wilt. NOG NIET BESCHIKBAAR."
                            />
                            <Tooltip
                                id="reasonTextLlmTooltip"
                                place="bottom"
                                content="Klik hier als u meer wilt weten hoe het antwoord tot stand is gekomen. NOG NIET BESCHIKBAAR."
                            />
                            <Tooltip
                                id="copyTextLlmTooltip"
                                place="bottom"
                                content="Klik hier als u het antwoord wilt kopiëren naar het klembord. NOG NIET BESCHIKBAAR."
                            />
                            <Tooltip
                                id="sourcesTextLlmTooltip"
                                place="bottom"
                                content="Klik hier als u de relevante passages wilt bekijken waar het antwoord is op gebaseerd."
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }

    function ChatUserResponse(response) {
        if (timeArray[response] === undefined) {
            timeArray[response] = showTime
        }
        return (
            <div>
                <div className="response">
                    <div className="userResponse">
                        <p className="responseText">
                            {response}
                        </p>
                        <div className="botTimeInBubble">
                            {timeArray[response]}
                        </div>
                    </div>
                    <div className="userResponseFiller"/>
                </div>
                <div className="userResponseIcon">
                    <div className="circle">
                        <p className="textLoginUser">{fetchUserLetter()}</p>
                    </div>
                </div>
            </div>
        )
    }

    const postUserInput = () => {
        if (useFilters !== null) {
            // answer is regarding the second selection
            if (inputTextVal === 'llm' || inputTextVal === "antwoord" || inputTextVal === 'chat') {
                setUseLLM(true)
            } else {
                setUsePassages(true)
            }
        } else if (question.length > 0) {
            // answer is regarding the first selection
            if (inputTextVal === 'ja' || inputTextVal === 'filter' || inputTextVal === 'filters' || inputTextVal === 'wel' || inputTextVal === 'wel filters' || inputTextVal === 'wel filteren') {
                setUseFilters(true)
            } else {
                setUseFilters(false)
            }
        }
        setInputTextVal("")
    }

    function ChatElements(elementName) {
        if (elementName['parameter'] === "first") {
            return (
                ChatUserResponse(beginChatFirstAnswer)
            )
        }

        if (elementName['parameter'] === "second") {
            return (
                ChatUserResponse(beginChatSecondAnswer)
            )
        }

        if (elementName['parameter'] === "third") {
            return (
                ChatUserResponse(question)
            )
        }

        if (elementName['parameter'] === "seventh") {
            return (
                ChatShowBotAnswer("Er zijn geen resultaten gevonden bij de opgegeven zoek opdracht.", false, false)
            )
        }

        if (elementName['parameter'] === "eight") {
            console.log("here")
            console.log(dataLLMResponseAdvanced)
            if (service === 'WatsonX') {
                return (
                    ChatShowBotAnswer(dataLLMResponse, true, false)
                )
            } else if (service === 'Watson') {
                return (
                    ChatShowBotAnswer(dataLLMResponseAdvanced, true, false)
                )
            }
        }

        if (elementName['parameter'] === 'filterChoice') {
            if (useFilters === true) {
                return (
                    ChatUserResponse("Ik wil filteren")
                )
            } else {
                return (
                    ChatUserResponse("Ik wil niet filteren")
                )
            }
        }

        if (elementName['parameter'] === 'useLlm') {
            return (
                ChatUserResponse("Laat jou de vraag beantwoorden")
            )
        }

        if (elementName['parameter'] === 'usePassages') {
            return (
                ChatUserResponse("Ik wil alleen de relevante passages zien")
            )
        }

        if (elementName['parameter'] === 'answeringQuestion') {
            return (
                ChatShowBotAnswer("Ik heb alle benodigde informatie. Ik ga aan de slag voor je.", false, false)

            )
        }

        if (elementName['parameter'] === 'documentsReady') {
            return (
                ChatShowBotAnswer("Zie de relevante passages bij jouw vraag aan de rechterzijde.", false, false)
            )
        }

        if (elementName['parameter'] === 'loading') {
            return (
                ChatShowBotAnswer("", false, true)
            )
        }
    }

    const SeekDocuments = async () => {
        let response = ""
        if (service === 'WatsonX') {
            const formData = new FormData();
            formData.append("question", question);
            formData.append("filters", useFilters)
            formData.append("filterData", JSON.stringify(filterData))
            formData.append("sliderVersion", JSON.stringify(sliderVersion))
            formData.append("sliderRange", JSON.stringify(sliderRange))
            formData.append("sliderExact", JSON.stringify(sliderExact))
            formData.append("dropdownSelection", JSON.stringify(dropdownSelection))
            formData.append("jwtToken", JSON.stringify(fetchToken()))
            response = await fetch(GeneralVariables.server_address + 'api/v1/seekDocuments', {
                method: 'POST',
                body: formData,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                }
            });
        } else if (service === 'Watson') {
            const formData = new FormData();
            formData.append("question", question);
            formData.append("jwtToken", JSON.stringify(fetchToken()))
            response = await fetch(GeneralVariables.server_address + 'api/v1/seekDocumentsIBM', {
                method: 'POST',
                body: formData,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                }
            });
        }

        if (response.status !== 200 && response.status !== 202) {
            console.log("Error sending the question")
        } else if (response.status === 202) {
            console.log("no results")
            setNoResults(true)
        } else {
            const data = await response.json();
            if (service === 'WatsonX') {
                orderedAssetIds = {...data['ordered_asset_ids']}
                delete data['ordered_asset_ids']
            }
            setDocuments(data)
            return Promise.resolve(data);
        }
    }

    const seekLLMAnswerAdvanced = async () => {
        const formData = new FormData();
        formData.append("question", question);
        formData.append("jwtToken", JSON.stringify(fetchToken()))
        let response = await fetch(GeneralVariables.server_address + 'api/v1/ragDocumentStreamIBM', {
            method: 'POST',
            body: formData,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        });

        if (response.status !== 200 && response.status !== 202) {
            console.log("Error sending the question")
        } else if (response.status === 202) {
            console.log("no results")
            setNoResults(true)
        } else {
            const data = await response.json();
            setLLMResponseAdvanced(data['answer'])
            console.log(dataLLMResponseAdvanced)
            delete data['answer']
            setDocuments(data)
            return Promise.resolve(data);
        }
    }

    const seekLLManswer = async (docs) => {
        setLLMResponse("");
        const doc_ids_only = Object.keys(docs)
        console.log(doc_ids_only)
        console.log(orderedAssetIds)
        const doc_ids = []
        for (var i = 0; i < doc_ids_only.length; i++) {
            doc_ids.push(docs[doc_ids_only[i]]["chunk_number"])
            console.log(docs[doc_ids_only[i]])
        }

        await fetchEventSource(GeneralVariables.server_address + "api/v1/ragDocumentStream", {
            method: "POST",
            headers: { Accept: "text/event-stream" },
            body: JSON.stringify({
                doc_ids,
                question,
                orderedAssetIds
            }),

            onopen(res) {
                if (res.ok && res.status === 200) {
                    console.log("Connection made ", res);
                } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
                    console.log("Client-side error ", res);
                }
            },

            onmessage(event) {
                try {
                    setLLMResponse((dataLLMResponse) => [...dataLLMResponse, event.data].join(""));
                } catch (e) {
                    setLLMResponse((dataLLMResponse) => [...dataLLMResponse, event].join(""));
                }
            },

            onclose() {
                console.log("Connection closed by the server");
            },

            onerror(err) {
                console.log("There was an error from server", err);
            },
        });
    };

    const SubmitQuestion = (method) => {
        if (method['parameter'] === 'llm') {
            if (service === 'WatsonX') {
                SeekDocuments().then((doc_ids) => {
                    seekLLManswer(doc_ids);
                })
            } else if (service === 'Watson') {
                seekLLMAnswerAdvanced().then();
            }
            setLlmResponseRequested(true)
        } else {
            SeekDocuments().then()
            setPassageRequestSend(true)
        }
    }

    function valuetext(value) {
        return `${value}°C`;
    }

    const RetrieveData = () => {
        fetch("../filters.json")
                .then((res) => {
                    if (!res.ok) {
                        throw new Error
                            (`HTTP error! Status: ${res.status}`);
                    }
                    return res.json();
                })
                .then((data) => (
                    fetchBusiness(data)
                ))
                .catch((error) =>
                       console.error("Unable to fetch data:", error));
    }

    function fetchBusiness(data) {
        setFilterData(data)

        Object.keys(data).map((key, i) => (
            sliderVersion[data[key]['name']] =  "between"
        ))
        // eslint-disable-next-line array-callback-return
        Object.keys(data).map((key, i) => {
            if (data[key]['options']['min'] !== undefined) {
                sliderRange[data[key]['name']] = [data[key]['options']['min'], data[key]['options']['max']]
                sliderExact[data[key]['name']] = data[key]['options']['min']
            }
        })
    }

    function SliderChange(name, e) {
        sliderVersion[name] = e.target.value
        setSliderVersion({...sliderVersion})
    }

    const handleChangeSlider = (event, value) => {
        sliderRange[event.target.name] = value
        setSliderRange({...sliderRange})
    };

    const handleChangeSliderCommit = (event) => {
        sliderRange[event.target.name] = event.target.value
        setSliderRange({...sliderRange})
    };

    const handleChangeExact = (event, value) => {
        sliderExact[event.target.name] = event.target.value
    }

    const handleChangeExactCommit = (event) => {
        sliderExact[event.target.name] = event.target.value
        setSliderExact({...sliderExact})
    }

    const renderSlider = (filterValues) => {
        if (sliderVersion[filterValues['name']] === 'exact') {
            return (
                <Slider
                    name={filterValues['name']}
                    min={filterValues['options']['min']}
                    max={filterValues['options']['max']}
                    step={filterValues['options']['increment']}
                    marks
                    aria-labelledby="input-slider"
                    value={(typeof sliderExact[filterValues['name']]) === 'number' ? sliderExact[filterValues['name']] : 5}
                    onChange={handleChangeExact}
                    onMouseUp={(e) => handleChangeExactCommit(e)}
                    valueLabelDisplay="auto"
                    sx={{
                         "& .MuiSlider-thumb": {
                            backgroundColor: "orange"
                         },
                         "& .MuiSlider-track": {
                            color: "#8ea0a5"
                         },
                         "& .MuiSlider-rail": {
                            color: "#8ea0a5"
                         },
                         '& .MuiSlider-active': {
                            color: "white"
                         }
                    }}
                />
            )
        } else {
            return (
                <Slider
                    name={filterValues['name']}
                    min={filterValues['options']['min']}
                    max={filterValues['options']['max']}
                    step={filterValues['options']['increment']}
                    marks
                    aria-labelledby="input-slider"
                    value={sliderRange[filterValues['name']]}
                    onChange={handleChangeSlider}
                    onMouseUp={(e) => handleChangeSliderCommit(e)}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    sx={{
                         "& .MuiSlider-thumb": {
                            backgroundColor: "orange"
                         },
                         "& .MuiSlider-track": {
                            color: "#8ea0a5"
                         },
                         "& .MuiSlider-rail": {
                            color: "#8ea0a5"
                         },
                         '& .MuiSlider-active': {
                            color: "#8ea0a5"
                         },
                         '& .MuiSlider-mark': {
                            backgroundColor: "white"
                         }
                    }}
                />
            )
        }
    }

    const handleChangeSelection = (e, name) => {
        dropdownSelection[name] = e
    }

    function DrawFilter(filterValues) {
        if (filterValues['method'] === 'slider') {
            return (
                <div className="filterOption">
                    <div className="filterTitle">
                        <Checkbox disabled/>
                        <p className="filterText">
                            {filterValues['name']}
                        </p>
                    </div>
                    <div className="radioGroupSlider">
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group-slider"
                                defaultValue={sliderVersion[filterValues['name']]}
                                onChange={(e) => SliderChange(filterValues['name'], e)}
                            >
                                <FormControlLabel value="exact" control={<Radio
                                    sx={{
                                        color: 'white',
                                        '&.Mui-checked': {
                                            color: 'orange',
                                        },
                                    }}/>} label={<Typography className="radioOptions">Exact</Typography>} />
                                <FormControlLabel value="between" control={<Radio sx={{
                                        color: 'white',
                                        '&.Mui-checked': {
                                            color: 'orange',
                                        },
                                    }}/>} label={<Typography className="radioOptions">Tussen</Typography>} />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="sliderBox">
                        <p className="sliderText">
                            {filterValues['options']['min']}
                        </p>
                        {renderSlider(filterValues)}
                        <p className="sliderText">
                            {filterValues['options']['max']}
                        </p>
                    </div>
                </div>
            )
        } else if (filterValues['method'] === 'selectionbox') {
            return (
                <div className="filterOption">
                    <div className="filterTitle">
                        <Checkbox disabled/>
                        <p className="filterText">
                            {filterValues['name']}
                        </p>
                    </div>
                    <div className="dropdownList">
                        <Select
                            id="ik"
                            isMulti
                            options={filterValues['options']}
                            className="list"
                            value={dropdownSelection[filterValues['name']]}
                            onChange={(e) => handleChangeSelection(e, filterValues['name'])}
                            />
                    </div>
                </div>
            )
        } else {
            return (
                <div/>)
        }
    }

    const ParseFilterData = () => {
        return (
            Object.keys(filterData).map((key, i) => (
                DrawFilter(filterData[key])
            ))
        )
    }


    function FilterViewer() {
        return (
            <div className="filters">
                {Object.keys(filterData).length > 0 ?
                    <ParseFilterData/>
                    :
                    <RetrieveData/>
                }
            </div>
        )
    }

    return (
        < div className="App">
            {header}
            <div className="body">
                {(useFilters === true && (!passageRequestSend && !llmResponseRequested)) ?
                    <div className="filterViewer">
                        <FilterViewer/>
                    </div>
                    :
                    <div className="filterViewer" style={{"borderColor": "transparent", "overflowY": "hidden", "backgroundColor": "transparent"}}/>
                }
                <div className="sideFiller"/>
                <div className="bodyChat">
                    <div className="chatWindow">
                        <div className="chat" >
                            {question.length > 0 &&
                                <ChatElements parameter={("third")}/>
                            }
                            {(question.length > 0 && service !== 'Watson') &&
                                <ChatAskQuestion parameter={("askForFiltering")} />
                            }
                            {useFilters !== null &&
                                <ChatElements parameter={("filterChoice")} />
                            }
                            {useFilters === true &&
                                <ChatAskQuestion parameter={("showFilterLocation")} />
                            }
                            {(useFilters !== null || service === 'Watson') &&
                                <ChatAskQuestion parameter={("answeringMethod")} />
                            }
                            {useLLM === true &&
                                <ChatElements parameter={("useLlm")} />
                            }
                            {usePassages === true &&
                                <ChatElements parameter={("usePassages")} />
                            }
                            {(useLLM === true || usePassages === true) &&
                                <ChatElements parameter={("answeringQuestion")} />
                            }
                            {(usePassages === true && !passageRequestSend) &&
                                <SubmitQuestion parameter={("passage")}/>
                            }
                            {(useLLM === true && !llmResponseRequested) &&
                                <SubmitQuestion parameter={("llm")}/>
                            }
                            {noResults &&
                                <ChatElements parameter={("seventh")} />
                            }
                            {(usePassages === true && Object.keys(documents).length === 0 && !noResults) &&
                                <ChatElements parameter={("loading")} />
                            }
                            {(usePassages === true && Object.keys(documents).length > 0) &&
                                <ChatElements parameter={("documentsReady")} />
                            }
                            {(dataLLMResponse.length > 0 || dataLLMResponseAdvanced.length > 0) &&
                                <ChatElements parameter={("eight")} />
                            }
                            {(useLLM === true && (dataLLMResponse.length === 0 && dataLLMResponseAdvanced.length === 0) && !noResults) &&
                                <ChatElements parameter={("loading")} />
                            }
                            <div id="myRef" ref={refChat} />
                            {scrollBottom()}
                        </div>
                        <hr className="chatDivider"/>
                        <div className="questionCenterLayout">
                            <div className="questionBox">
                                <div className="questionBoxLeftIcon">
                                    <ChatBubbleOutlineIcon />
                                </div>
                                <div className="inputField" disabled={usePassages === true || useLLM === true}>
                                    <TextField id="userInput" label={ChatOptions.typeResponseSecond} variant="standard" fullWidth
                                       InputLabelProps={{
                                           style: {
                                               textOverflow: 'ellipsis',
                                               whiteSpace: 'nowrap',
                                               overflow: 'hidden',
                                               width: '100%',
                                               color: 'white'
                                           }
                                       }}
                                       sx={{
                                           input: {
                                               color: "white",
                                               borderBottom: "2px solid gray",
                                           },
                                           "& .MuiInput-underline:after": {
                                               borderBottomColor: "orange"
                                           }
                                       }}
                                       onKeyDown={(ev) => {
                                           if (ev.key === 'Enter') {
                                               postUserInput()
                                               ev.target.value = ""
                                           }
                                       }}
                                       onChange={(ev) => {
                                            setInputTextVal(ev.target.value)
                                       }}
                                       value={inputTextVal}
                                    />
                                </div>
                                <div className="questionBoxRightIcon" disabled={usePassages === true || useLLM === true}>
                                    <ArrowUpwardIcon className="arrowUp" style={{color: 'white'}}
                                         onClick={() => postUserInput()}/>
                                </div>
                            </div>
                            <div className="generalOptions">
                                <div className="generalButtonRestart" onClick={routeChangeVraag} data-tooltip-id="restartChatTooltip">
                                    <RestartAltIcon />
                                </div>
                                <Tooltip
                                    id="restartChatTooltip"
                                    place="bottom"
                                    content="Klik hier als u een nieuwe chat wilt starten."
                                />
                                <div className="generalButtonFiller" />
                                <div className="generalButtonSave" data-tooltip-id="saveChatTooltip">
                                    <SaveAltIcon />
                                </div>
                                <Tooltip
                                    id="saveChatTooltip"
                                    place="bottom"
                                    content="Klik hier om de chat op te slaan. NOG NIET BESCHIKBAAR."
                                />
                                <div className="generalButtonFiller" />
                                <div className="generalButtonMic" data-tooltip-id="micChatTooltip">
                                    <KeyboardVoiceIcon />
                                </div>
                                <Tooltip
                                    id="micChatTooltip"
                                    place="bottom"
                                    content="Klik hier om via spraak te reageren. NOG NIET BESCHIKBAAR."
                                />
                                <div className="generalButtonFiller" />
                                <div className="generalButtonInfo" onClick={routeChange}  data-tooltip-id="infoPageTooltip">
                                    <p className="generalButtonText" >?</p>
                                </div>
                                <Tooltip
                                    id="infoPageTooltip"
                                    place="bottom"
                                    content="Klik hier als u naar de info pagina wilt."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sideFiller"/>
                {((Object.keys(documents).length > 0 && usePassages === true) || (Object.keys(documents).length > 0 && useLLM === true && passageViewRequest === true)) ?
                    <div className="chunkViewer">
                        <ChunkViewer parameter={{documents}}/>
                    </div>
                    :
                    <div className="chunkViewer" style={{"borderColor": "transparent", "overflowY": "hidden", "backgroundColor": "transparent"}}/>
                }
            </div>
            <StaticFooter/>
        </div>
    );
}

export default Chat;
